// Import the functions you need from the SDKs you need
import { AppSettings } from "@/shared/app-common";
import { initializeApp, getApps } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
const isProd = process.env.NODE_ENV === 'production' && !process.env.NEXT_APP_API_HOST.includes("www-test.drfans.com");
// const isProd = process.env.NODE_ENV === 'production';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
function initFirebase() {
  // Initialize Firebase
  const apps = getApps();
  if (isProd && !apps.length) {
    const app = initializeApp(AppSettings.firebaseConfig);
    const analytics = isSupported().then(yes => yes ? getAnalytics(app) : null);

  }
}

initFirebase();
