// import { useTranslation } from "next-i18next"

// App 1:DRfans, 2:Quotesecho
const AppType = process.env.NEXT_APP_TENANTID

export const isDrFans = ("1" == AppType)

// name
const getAppName = () => {
    switch (AppType) {
        case "1":
            return "DRfans"
        case "2":
            return "Quotesecho"
        case "3":
        case "5":
            return "Momsgpts"
        case "4":
            return "Huntpink"
        default:
            return ""
    }
}

const lowerName = getAppName().toLowerCase()

// app logo
const getAppLogo = `/images/layouts/${lowerName}-logo.png`
// app net logo
const getAppNetLogo = `https://gpts-prod.s3.us-west-1.amazonaws.com/logo/${lowerName}-logo.png`
// app name
const getAppLogoName = `/images/layouts/${lowerName}-name.png`
// app ico
const getAppICO = `/${lowerName}_favicon.ico`
// app title
const getAppTitle = () => {
    switch (AppType) {
        case "1":
            return "DRfans | Your Source for New Dark Romance Discoveries"
        case "2":
            return "QuotesEcho | Discover infinite quotes and relevant GPTs at QuotesEcho"
        case "3":
            return "Momsgpts | Expert Parenting Advice & Tips for Modern Moms"
        case "4":
            return "HuntPink | Discover the Best in Pink Fashion & Lifestyle"
        case "5":
            return "Conselhos de Parentalidade de Especialistas & Dicas para Mães Modernas"
        default:
            return ""
    }
}

// app desc
const getAppDesc = () => {
    switch (AppType) {
        case "1":
            return `Stay updated with the latest books, in-depth reviews, and innovative explorations in the genre. Uncover the newest treasures of dark romance with DRfans.`
        case "2":
            return `Explore a curated collection of inspiring quotes, delve into their profound meanings. A treasure trove for quote enthusiasts and seekers of wisdom.`
        case "3":
            return `Find tips, health info, and support for each step of motherhood at Momsgpts.`
        case "4":
            return `Find everything pink! Fashion, decor, and more. Your pink lifestyle starts here.`
        case "5":
            return "Descubra dicas, informações de saúde e apoio para cada etapa da maternidade no Momsgpts."
        default:
            return ``
    }
}

// app sub title
const getAppHeadSubTitle = () => {
    switch (AppType) {
        case "1":
            return `Advanced GPTs in Dark Romance`
        case "2":
            return `Advanced GPTs in Quotesecho`
        case "3":
            return `Advanced GPTs in Momsgpts`
        case "4":
            return `Advanced GPTs in Huntpink`
        case "5":
            return `GPTs Avançados em Momsgpts`
        default:
            return ""
    }
}

//
const getFirebaseConfig = () => {
    switch (AppType) {
        case "1":
            return {
                apiKey: "AIzaSyDDZQ6AqZbl8YOC8f-3P_0tNCxa72Ge8Lw",
                authDomain: "gpts-factory.firebaseapp.com",
                projectId: "gpts-factory",
                storageBucket: "gpts-factory.appspot.com",
                messagingSenderId: "375183826106",
                appId: "1:375183826106:web:4931ea60fcedccb5d5f683",
                measurementId: "G-65SZP316P3"
            }
        case "2":
            return {
                apiKey: "AIzaSyDDZQ6AqZbl8YOC8f-3P_0tNCxa72Ge8Lw",
                authDomain: "gpts-factory.firebaseapp.com",
                projectId: "gpts-factory",
                storageBucket: "gpts-factory.appspot.com",
                messagingSenderId: "375183826106",
                appId: "1:375183826106:web:613cb91e7f078399d5f683",
                measurementId: "G-0HECRFPL8E"
            }
        case "3":
            return {
                apiKey: "AIzaSyDDZQ6AqZbl8YOC8f-3P_0tNCxa72Ge8Lw",
                authDomain: "gpts-factory.firebaseapp.com",
                projectId: "gpts-factory",
                storageBucket: "gpts-factory.appspot.com",
                messagingSenderId: "375183826106",
                appId: "1:375183826106:web:0b81a90a8888c3fbd5f683",
                measurementId: "G-7TX1K2XV3T"
            };
        case "4":
            return {
                apiKey: "AIzaSyDDZQ6AqZbl8YOC8f-3P_0tNCxa72Ge8Lw",
                authDomain: "gpts-factory.firebaseapp.com",
                projectId: "gpts-factory",
                storageBucket: "gpts-factory.appspot.com",
                messagingSenderId: "375183826106",
                appId: "1:375183826106:web:6cb7d0065c05673bd5f683",
                measurementId: "G-E477H376F2"
            }
        case "5":
            return {
                apiKey: "AIzaSyDDZQ6AqZbl8YOC8f-3P_0tNCxa72Ge8Lw",
                authDomain: "gpts-factory.firebaseapp.com",
                projectId: "gpts-factory",
                storageBucket: "gpts-factory.appspot.com",
                messagingSenderId: "375183826106",
                appId: "1:375183826106:web:f5d2e451e42e6d2fd5f683",
                measurementId: "G-FCLYH1NTZ0"
            }
        default:
            return {}
    }
}

interface AppSettings {
    type: number,
    name: string,
    logoUrl: string,
    logoPath: string,
    namePath: string,
    icoPath: string,
    title: string,
    headSubTitle: string,
    desc: string,
    firebaseConfig: {},
    navItems: [],
}

export const AppSettings = {
    type: AppType,
    name: getAppName(),
    logoUrl: getAppNetLogo,
    logoPath: getAppLogo,
    namePath: getAppLogoName,
    icoPath: getAppICO,
    title: getAppTitle(),
    headSubTitle: getAppHeadSubTitle(),
    desc: getAppDesc(),
    firebaseConfig: getFirebaseConfig(),
}

