import enUS from 'antd/lib/locale/en_US';
import zhCN from 'antd/lib/locale/zh_CN';
import es_ES from 'antd/lib/locale/es_ES';
import pt_BR from 'antd/lib/locale/pt_BR';
import fr_FR from 'antd/lib/locale/fr_FR';
import ko_KR from 'antd/lib/locale/ko_KR';
import ja_JP from 'antd/lib/locale/ja_JP';
import de_DE from 'antd/lib/locale/de_DE';


interface AppLanguage {
    defaultLocale: string,
    locales: string[],
}

export const CurrentLanguage = (locale: string | undefined) => {
    if (locale == undefined || locale.length == 0) {
        return {
            "code": "en",
            "name": "English",
            "locale": enUS,
        }
    }
    const item = AllSupportLanguages.filter((item) => {
        return item.code == locale
    })
    if (item.length > 0) {
        return item[0]
    }
    return {
        "code": "en",
        "name": "English",
        "locale": enUS,
    }
}

export const AllSupportLanguages = [
    {
        "name": "English",
        "code": "en",
        "locale": enUS,
    },
    {
        "name": "español",
        "code": "es",
        "locale": es_ES,
    },
    {
        "name": "Português",
        "code": "pt",
        "locale": pt_BR,
    },
    {
        "name": "Français",
        "code": "fr",
        "locale": fr_FR,
    },
    {
        "name": "Deutsch",
        "code": "de",
        "locale": de_DE,
    },
    {
        "name": "한국인",
        "code": "ko",
        "locale": ko_KR,
    },
    {
        "name": "日本語",
        "code": "ja",
        "locale": ja_JP,
    },
    {
        "name": "简体中文",
        "code": "zh",
        "locale": zhCN
    }
]


export const AppLanguage = {
    defaultLocale: "en",
    locales: AllSupportLanguages.map((item) => { return item.code }),
}

